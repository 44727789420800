// rewards level reducer
import { get } from 'lodash';
import {
  FETCH_REWARD_PROGRAM_RULES,
  FETCH_BRAND_DEAL_DATA,
  CLEAR_BRAND_DEAL_DATA,
  FETCH_BRAND_OUTLETS,
  FETCH_GUEST_COUPONS,
  FETCH_REWARD_POINTS_TRANSACTIONS,
  BRAND_REWARD_GUEST_DETAILS,
  FETCH_REWARD_DEALS,
  CLEAR_GUEST_COUPONS,
  FETCH_REWARD_POINTS,
  UPDATE_GUEST_COUPON_FILTER,
  UPDATE_REWARD_POINT_TRANSACTION_FILTER,
  DISPATCH_CALLBACK_URL,
  SIGNUP_DETAILS,
  FETCH_VIP_PASSES,
  FETCH_VIP_PASS_DATA,
  FETCH_VIP_PASS_COUPONS,
  CLEAR_VIP_PASS_DATA,
  FETCH_PASS_MEMBERSHIP_BY_ID,
  FETCH_PASS_MEMBERSHIPS,
  CLEAR_PASS_MEMBERSHIPS,
  DISPATCH_SUBSCRIBED_MEMBERSHIP,
  FETCH_ALL_SUB_BRANDS,
  UPDATE_PAY_EARN_DATA,
  EMPTY_REWARD_GUEST_DETAILS,
  UPDATE_BOOK_TABLE_DATA,
  FETCH_GUEST_BOGO_COUPONS,
  FETCH_GUEST_LAST_ORDER,
  FETCH_REFERRAL_CODE,
  FETCH_REFERRAL_DEALS,
} from '../../actions/rewards/actionType';

const rewards = (state = {}, action = {}) => {
  if (action.type === FETCH_GUEST_COUPONS || action.type === CLEAR_GUEST_COUPONS) {
    const { guestCoupons } = action;
    return {
      ...state,
      guestCoupons: { ...get(state, 'guestCoupons', {}), ...guestCoupons },
    };
  } else if (action.type === FETCH_GUEST_BOGO_COUPONS) {
    const { guestBogoCoupons } = action;
    return {
      ...state,
      guestBogoCoupons,
    };
  } else if (action.type === FETCH_REWARD_PROGRAM_RULES) {
    const { rewardRules } = action;
    return {
      ...state,
      rewardRules,
    };
  } else if (action.type === FETCH_BRAND_DEAL_DATA) {
    const { brandDealDetail } = action;
    return {
      ...state,
      brandDealDetail,
    };
  } else if (action.type === CLEAR_BRAND_DEAL_DATA) {
    return {
      ...state,
      brandDealDetail: {},
    };
  } else if (action.type === FETCH_BRAND_OUTLETS) {
    const { brandOutlets } = action;
    return {
      ...state,
      brandOutlets,
    };
  } else if (action.type === FETCH_REWARD_POINTS_TRANSACTIONS) {
    const { rewardPointTransactions } = action;
    return {
      ...state,
      rewardPointTransactions: {
        ...get(state, 'rewardPointTransactions', {}),
        ...rewardPointTransactions,
      },
    };
  } else if (action.type === BRAND_REWARD_GUEST_DETAILS) {
    const { rewardGuestDetails } = action;
    return {
      ...state,
      rewardGuestDetails: { ...get(state, 'rewardGuestDetails', {}), ...rewardGuestDetails },
    };
  } else if (action.type === EMPTY_REWARD_GUEST_DETAILS) {
    return {
      ...state,
      rewardGuestDetails: {},
    };
  } else if (action.type === FETCH_REWARD_DEALS) {
    const { rewardDeals } = action;
    return {
      ...state,
      rewardDeals,
    };
  } else if (action.type === FETCH_REWARD_POINTS) {
    const { rewardPoints } = action;
    return {
      ...state,
      rewardPoints,
    };
  } else if (action.type === UPDATE_GUEST_COUPON_FILTER) {
    const { filter } = action;
    return {
      ...state,
      guestCoupons: { ...get(state, 'guestCoupons', {}), filter },
    };
  } else if (action.type === UPDATE_REWARD_POINT_TRANSACTION_FILTER) {
    const { filter } = action;
    return {
      ...state,
      rewardPointTransactions: { ...get(state, 'rewardPointTransactions', {}), filter },
    };
  } else if (action.type === DISPATCH_CALLBACK_URL) {
    const { callbackUrl } = action;
    return {
      ...state,
      callbackUrl,
    };
  } else if (action.type === SIGNUP_DETAILS) {
    const { signupDetails } = action;
    return {
      ...state,
      signupDetails,
    };
  } else if (action.type === FETCH_VIP_PASSES) {
    const { vipPasses } = action;
    return {
      ...state,
      vipPasses,
    };
  } else if (action.type === FETCH_VIP_PASS_DATA) {
    const { vipPassDetail } = action;
    return {
      ...state,
      vipPassDetail,
    };
  } else if (action.type === FETCH_VIP_PASS_COUPONS) {
    const { vipPassCoupons } = action;
    return {
      ...state,
      vipPassCoupons,
    };
  } else if (action.type === CLEAR_VIP_PASS_DATA) {
    return {
      ...state,
      vipPassDetail: {},
    };
  } else if (action.type === FETCH_PASS_MEMBERSHIP_BY_ID) {
    const { membershipId } = action;
    return {
      ...state,
      membershipId,
    };
  } else if (action.type === FETCH_PASS_MEMBERSHIPS) {
    const { passMemberships } = action;
    return {
      ...state,
      passMemberships,
    };
  } else if (action.type === CLEAR_PASS_MEMBERSHIPS) {
    return {
      ...state,
      passMemberships: [],
    };
  } else if (action.type === DISPATCH_SUBSCRIBED_MEMBERSHIP) {
    const { subscribedMembership } = action;
    return {
      ...state,
      subscribedMembership,
    };
  } else if (action.type === FETCH_ALL_SUB_BRANDS) {
    const { subBrands } = action;
    return {
      ...state,
      subBrands,
    };
  } else if (action.type === UPDATE_PAY_EARN_DATA) {
    const { payEarn = {}, cart = {}, order = {} } = action;
    return {
      ...state,
      payEarn: { ...state.payEarn, ...payEarn, cart, order },
    };
  } else if (action.type === UPDATE_BOOK_TABLE_DATA) {
    const { bookTable = {}, slots = [], booking = {}, order = {} } = action;

    return {
      ...state,
      bookTable: { ...state.bookTable, ...bookTable, slots, booking, order },
    };
  } else if (action.type === FETCH_REFERRAL_CODE) {
    const { referralCode } = action;

    return {
      ...state,
      referralCode,
    };
  } else if (action.type === FETCH_REFERRAL_DEALS) {
    const { referralDeals } = action;

    return {
      ...state,
      referralDeals,
    };
  } else if (action.type === FETCH_GUEST_LAST_ORDER) {
    const { guestLastOrder } = action;
    return {
      ...state,
      guestLastOrder,
    };
  }

  return state;
};

export default rewards;
