export const FETCH_GUEST_COUPONS = 'FETCH_GUEST_COUPONS';

export const FETCH_GUEST_BOGO_COUPONS = 'FETCH_GUEST_BOGO_COUPONS';

export const CLEAR_GUEST_COUPONS = 'CLEAR_GUEST_COUPONS';

export const FETCH_REWARD_PROGRAM_RULES = 'FETCH_REWARD_PROGRAM_RULES';

export const FETCH_BRAND_DEAL_DATA = 'FETCH_BRAND_DEAL_DATA';

export const CLEAR_BRAND_DEAL_DATA = 'CLEAR_BRAND_DEAL_DATA';

export const FETCH_BRAND_OUTLETS = 'FETCH_BRAND_OUTLETS';

export const FETCH_REWARD_POINTS_TRANSACTIONS = 'FETCH_REWARD_POINTS_TRANSACTIONS';

export const BRAND_REWARD_GUEST_DETAILS = 'BRAND_REWARD_GUEST_DETAILS';

export const EMPTY_REWARD_GUEST_DETAILS = 'EMPTY_REWARD_GUEST_DETAILS';

export const FETCH_BRAND_DEALS = 'FETCH_BRAND_DEALS';

export const FETCH_REWARD_DEALS = 'FETCH_REWARD_DEALS';

export const FETCH_REWARD_POINTS = 'FETCH_REWARD_POINTS';

export const UPDATE_GUEST_COUPON_FILTER = 'UPDATE_GUEST_COUPON_FILTER';

export const UPDATE_REWARD_POINT_TRANSACTION_FILTER = 'UPDATE_REWARD_POINT_TRANSACTION_FILTER';

export const DISPATCH_CALLBACK_URL = 'DISPATCH_CALLBACK_URL';

export const SIGNUP_DETAILS = 'SIGNUP_DETAILS';

export const FETCH_VIP_PASSES = 'FETCH_VIP_PASSES';

export const FETCH_VIP_PASS_DATA = 'FETCH_VIP_PASS_DATA';

export const FETCH_VIP_PASS_COUPONS = 'FETCH_VIP_PASS_COUPONS';

export const CLEAR_VIP_PASS_DATA = 'CLEAR_VIP_PASS_DATA';

export const FETCH_PASS_MEMBERSHIP_BY_ID = 'FETCH_PASS_MEMBERSHIP_BY_ID';

export const SUBSCRIBE_PASS_MEMBERSHIP = 'SUBSCRIBE_PASS_MEMBERSHIP';

export const FETCH_PASS_MEMBERSHIPS = 'FETCH_PASS_MEMBERSHIPS';

export const CLEAR_PASS_MEMBERSHIPS = 'CLEAR_PASS_MEMBERSHIPS';

export const DISPATCH_SUBSCRIBED_MEMBERSHIP = 'DISPATCH_SUBSCRIBED_MEMBERSHIP';

export const FETCH_ALL_SUB_BRANDS = 'FETCH_ALL_SUB_BRANDS';

export const UPDATE_PAY_EARN_DATA = 'UPDATE_PAY_EARN_DATA';

export const UPDATE_BOOK_TABLE_DATA = 'UPDATE_BOOK_TABLE_DATA';

export const FETCH_GUEST_LAST_ORDER = 'FETCH_GUEST_LAST_ORDER';

export const VALIDATE_REFERRAL_CODE = 'VALIDATE_REFERRAL_CODE';

export const FETCH_REFERRAL_CODE = 'FETCH_REFERRAL_CODE';

export const FETCH_REFERRAL_DEALS = 'FETCH_REFERRAL_DEALS';
