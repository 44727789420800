import React from 'react';
import { isNil, isEmpty, padStart, get, memoize, isEqual } from 'lodash';
import { detect } from 'detect-browser';
import Cookies from 'universal-cookie';
import { FormattedMessage } from 'react-intl';
import {
  imagePatternDivider,
  highAvailabilityServerAddress,
  suggestedAmountGrowthFactor,
  whitelabelSubdomainExceptions,
  operatingPlatformsList,
  onlinePaymentTypesConfig,
  greetingTimeSalutationsMap,
  timeOfDayStrings,
} from '../constants/globals';
import { constants } from '../constants/configuration';
import { layoutConfiguration } from '../constants/layoutConfiguration';
import routes from '../routes';
import DeviceDetector from 'device-detector-js';
import history from '../helpers/history';
import rewardsRoutes from '../routes/rewards';
import { pointUnits } from '../containers/Rewards/config';
import resourcesConfig from '../resources';

const cookies = new Cookies();

// helper methods
export const replaceAt = (string = '', index, replacement) => {
  return string.substr(0, index) + replacement + string.substr(index + replacement.length);
};

export const getPageWidth = () => {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth,
  );
};

export const getPriceInr = (price) => `₹ ${price ? roundOff(price, 2) : 0}`;

export const drawLine = (canvas, begin, end, color) => {
  canvas.beginPath();
  canvas.moveTo(begin.x, begin.y);
  canvas.lineTo(end.x, end.y);
  canvas.lineWidth = 4;
  canvas.strokeStyle = color;
  canvas.stroke();
};

// get color based on index
// TODO: Clean this function up if it's no longer being used
export const getColorIndex = (index) => {
  // return index % 2 ? '#f5f5f5' : '#fff';
};

export const scrollElementIntoView = (id, top) => {
  const el = document.getElementById(id);
  !isNil(el) &&
    el.parentElement.scrollTo({
      ...(top !== undefined && { top }),
      left: el.offsetLeft,
      behavior: 'smooth',
    });
};

export const degreesToRadians = (degrees) => {
  return (degrees * Math.PI) / 180;
};

export const radiansToDegrees = (radians) => {
  return radians * (180 / Math.PI);
};

export const fetchGeoLocation = async (geoLocationCallback, error) => {
  await navigator.geolocation.getCurrentPosition((position) => {
    geoLocationCallback(position);
  }, error);
};

/**
 *
 * @param {Float} latitude1
 * @param {Float} longitude1
 * @param {Float} latitude2
 * @param {Float} longitude2
 * @returns distance between the coordinates
 */
export const checkDistanceBetweenCoordinates = (
  { latitude1, longitude1, latitude2, longitude2 },
  roundOff = true,
) => {
  if (latitude1 == latitude2 && longitude1 == longitude2) {
    return 0;
  } else {
    const theta = longitude1 - longitude2;
    let distance =
      Math.sin(degreesToRadians(latitude1)) * Math.sin(degreesToRadians(latitude2)) +
      Math.cos(degreesToRadians(latitude1)) *
        Math.cos(degreesToRadians(latitude2)) *
        Math.cos(degreesToRadians(theta));
    distance = Math.acos(distance);
    distance = radiansToDegrees(distance);
    distance = distance * 60 * 1.1515;
    distance = distance * 1.609344; // km factor
    return roundOff ? Math.round(distance, 1) : distance;
  }
};

export const getTableLabel = (type, tableTitle, tableLabel) => {
  let label = {
    SP: tableLabel,
    DI: !isEmpty(tableLabel)
      ? `${!isEmpty(tableTitle) ? tableTitle : constants.tableType.table} ${tableLabel}`
      : '',
    DT: tableLabel,
    RO: tableLabel,
    DL: tableLabel,
  };
  return label[type];
};

export const dineInModes = ['DI', 'RO'];

export const getCurrentRestaurantCode = ({ restaurantCode, tableNumber }) => {
  let currentRestaurantCode = '';
  if (!isEmpty(restaurantCode) && !isEmpty(tableNumber)) {
    currentRestaurantCode = `/${routes.containers.restaurant}/${
      restaurantCode + padStart(tableNumber, 3, '0')
    }`;
  } else {
    currentRestaurantCode = `/${routes.containers.scan}`;
  }
  return currentRestaurantCode;
};

/* Credits: To Title Case © 2018 David Gouch | https://github.com/gouch/to-title-case */
// Converts a string to Title Case
// eslint-disable-next-line no-extend-native
export const toTitleCase = (string) => {
  const smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i;
  const alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/;
  const wordSeparators = /([ :–—-])/;

  return string
    .split(wordSeparators)
    .map(function (current, index, array) {
      if (
        /* Check for small words */
        current.search(smallWords) > -1 &&
        /* Skip first and last word */
        index !== 0 &&
        index !== array.length - 1 &&
        /* Ignore title end and subtitle start */
        array[index - 3] !== ':' &&
        array[index + 1] !== ':' &&
        /* Ignore small words that start a hyphenated phrase */
        (array[index + 1] !== '-' || (array[index - 1] === '-' && array[index + 1] === '-'))
      ) {
        return current.toLowerCase();
      }

      /* Ignore intentional capitalization */
      if (current.substr(1).search(/[A-Z]|\../) > -1) {
        return current;
      }

      /* Ignore URLs */
      if (array[index + 1] === ':' && array[index + 2] !== '') {
        return current;
      }

      /* Capitalize the first letter */
      return current.replace(alphanumericPattern, function (match) {
        return match.toUpperCase();
      });
    })
    .join('');
};

// Format address lines to comma separated title case text
export const formatAddress = ({ addressLine1, landmark, localityName, localitySecondaryText }) =>
  toTitleCase(
    [addressLine1, landmark, localityName, localitySecondaryText]
      .filter((part) => part && part.length)
      .join(', '),
  );

/**
 *
 * @param {Number} index Index of the item which contains renderable image
 * @param {Number} renderableItemsCount Total count of renderable items (containing image(s))
 */
export const renderImageType = (index, renderableItemsCount) => {
  const remainder = index % imagePatternDivider;
  switch (remainder) {
    case 0:
      return 'bg'; // Zeroth index image should always be big
    case 1:
      if (renderableItemsCount < imagePatternDivider) {
        // If items count is less than pattern limiter, then return bg
        return 'bg';
      } else {
        // Else if it's the last index of the array, return bg, else sm
        return index === renderableItemsCount - 1 ? 'bg' : 'sm';
      }
    default:
      return 'sm';
  }
};

export const renderCount = (count) => {
  switch (count) {
    case 1:
      return `${count}st`;
    case 2:
      return `${count}nd`;
    case 3:
      return `${count}rd`;
    default:
      return `${count}th`;
  }
};

/**
 * function to calculate total amount sum
 * @param {*} collection
 * @param {*} decimal
 */
export const getTotalAmountSum = (collection, decimal) => {
  let roundOff = Math.pow(10, decimal);
  let sum = collection.reduce(
    (accumulator, currentValue) => accumulator + currentValue.totalAmount,
    0,
  );
  return Math.round(sum * roundOff) / roundOff;
};

/**
 * Returns a boolean indicating whether the app visitor is logged in or not.
 */
export const isUserLoggedIn = () => {
  const loginData = JSON.parse(localStorage.getItem('GuestLoginData'));
  return isEmpty(loginData) ? false : true;
};

/**
 * Checks if the user-agent is online by using Navigator API and pinging a highly available server on the internet
 */
export const checkInternetConnectivity = () => {
  if (!navigator.onLine) {
    // This indicates that the user-agent is definitely offline
    const userOnlinePromise = new Promise((resolve, reject) => {
      resolve(false);
    });
    return userOnlinePromise;
  } else {
    // Try pinging a highly available server to double check if the user-agent is able to access the internet
    return pingServer(highAvailabilityServerAddress).then((isAvailable) => isAvailable);
  }
};

/**
 * Ping a server existing at the passed server address
 * @param {String} serverAddress A server url or dns
 * @returns {Promise} Resolution of promise returns a boolean indicating if the hit on the server was successful
 */
export const pingServer = async (serverAddress) => {
  if (!serverAddress) {
    return false;
  }

  try {
    await fetch(serverAddress, { mode: 'no-cors' }); // Fetch trial, if successful, will continue here instead of jumping to catch
    return true;
  } catch (error) {
    return false;
  }
};

// Detects if the user agent is in standalone mode
export const isInStandaloneMode = () =>
  'standalone' in window.navigator && window.navigator.standalone;

/**
 * Returns an object containing browser name and version along with additional info about the user agent running the webapp
 * @returns {Object} Browser info
 */
export const detectWebAppBrowser = () => detect();

export const detectWebAppOS = () => {
  const browser = detectWebAppBrowser();
  return browser ? browser.os : null;
};

/**
 * Extract the subdomain from the URL. Returns false if not found.
 * @return {Boolean | String} Extracted subdomain, otherwise false
 */
export const extractWhitelabelSubdomain = () => {
  // Get hostname from the targetHostName set in window._customProps at the document level, fallback to hostname attached to the location object of the document
  const hostname = cookies.get('targetHostName') || window.location.hostname;
  if (isEmpty(hostname)) {
    return false;
  }

  const subDomain = hostname.split('.')[0];
  if (whitelabelSubdomainExceptions.includes(subDomain)) {
    // Skip URLs that have hostnames starting with 'www' or webapp's primary domain
    return false;
  }

  return subDomain || false;
};

// As long as the callback continues to be invoked, it will not be triggered
export const debounce = (func, delay = 200, immediate = false) => {
  let timeout;

  return function () {
    const context = this,
      args = arguments;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, delay);
    if (callNow) func.apply(context, args);
  };
};

// As long as the callback continues to be invoked, raise on every interval
export const throttle = (func, interval = 200, immediate = false) => {
  let timeout = null;
  let initialCall = true;

  return function () {
    const context = this,
      args = arguments;
    const callNow = immediate && initialCall;
    const later = () => {
      func.apply(context, args);
      timeout = null;
    };

    if (callNow) {
      initialCall = false;
      later();
    }

    if (!timeout) {
      timeout = setTimeout(later, interval);
    }
  };
};

/**
 * Returns a restaurant entity's social profile link by social platform's app code
 *
 * @param {string} socialAppCode Social platform's app code
 * @param {array} restaurantAdditionalParams Additional parameters object in restaurant entity's config data
 * @return {string} restaurantEntitySocialLink Restaurant entity's social profile link
 */
export const getRestaurantEntitySocialLinkByCode = (
  socialAppCode = '',
  restaurantAdditionalParams = [],
) => {
  const matchingAdditionalParamObj = restaurantAdditionalParams.find(
    (additionalParam) => get(additionalParam, 'code') === socialAppCode,
  );
  const restaurantEntitySocialLink = get(matchingAdditionalParamObj, 'value', '');
  return restaurantEntitySocialLink || '';
};

/**
 * Sorts an object by its keys based on a compare function (optional)
 * @param {object} unsortedObject Unsorted object
 * @param {Function} [compareFunction] Optional callback which provides a compare function to serve as the basis for sort, default sort based on ASCII comparator
 *
 * @return {object} sortedObject Sorted object
 */
export const sortObjectByKeysUsingComparator = (unsortedObject, compareFunction) => {
  let sortedObject = {};
  Object.keys(unsortedObject)
    .sort(compareFunction)
    .forEach(function (key) {
      sortedObject[key] = unsortedObject[key];
    });

  return sortedObject;
};

/**
 * return origin from string from current url
 * if url has no query string than get origin from document.referrer
 * but in some case we get empty in referrer of document in that case default origin will be 'ext'
 */
export const getURLOrigin = () => {
  const queryString = get(window, 'location.search', '');
  const searchParams = new URLSearchParams(queryString);
  const defaultQueryString = !!document.referrer ? document.referrer : 'ext';

  return searchParams.has('origin') ? searchParams.get('origin') : defaultQueryString;
};

/**
 * Registers event listerners to start tracking user activity on the webapp and executes a callback delimited by a throttle
 * @param {Function} func Callback to throttle-execute when tracking user activity
 */
export const activateUserActivityTracker = (func, userActivityTrackingThrottleTime) => {
  window.addEventListener('load', debounce(func, userActivityTrackingThrottleTime, true));
  window.addEventListener('mousemove', debounce(func, userActivityTrackingThrottleTime, true));
  window.addEventListener('scroll', debounce(func, userActivityTrackingThrottleTime, true));
  window.addEventListener('keydown', debounce(func, userActivityTrackingThrottleTime, true));
  window.addEventListener('touchstart', debounce(func, userActivityTrackingThrottleTime, true));
};

/**
 * Rounds off the given value upto 'precision' places of decimal
 *
 * @param {Number} value Numeric value to be rounded off
 * @param {Number} precision Places after dot to round off upto
 */
export const roundOff = (value, precision = 0) => {
  let precisionMultiplier = Math.pow(10, precision);
  return Math.round((value + Number.EPSILON) * precisionMultiplier) / precisionMultiplier; // Number.EPSILON offset helps round off figures ending in 5 like 0.05, 1.145, uniformly across all browsers
};

/**
 * To check the passed element is in viewport of device or not
 *
 * @param {element} object DOM object of element
 */
export const isElementInViewPort = ({ elements, headerHeight }) => {
  // provides the element’s position and its relative position to the viewport.
  const elementKeys = Object.keys(elements);
  let positionOfLastElementFromTop = {};
  elementKeys.forEach((key) => {
    const elementChildren = elements[key] && get(elements[key], 'current.children', []);
    //getBoundingClientRect() will give element top,bottom,height,width value
    if (elementChildren.length > 0) {
      const lastChild = elementChildren[elementChildren.length - 1].getBoundingClientRect();
      if (lastChild.top - headerHeight > 0) {
        positionOfLastElementFromTop[lastChild.top] = key;
      }
    }
  });
  let minPositionFromTop = Math.min(...Object.keys(positionOfLastElementFromTop));
  return positionOfLastElementFromTop[minPositionFromTop];
};

/**
 * Scroll element to top or  bottom
 *
 * @param {id} string id of element
 * @param {top} boolean true for top and false for bottom
 */
export const scrollIntoView = ({ id, top = true }) => {
  const element = document.getElementById([id]);
  !isNil(element) && element.scrollIntoView(top);
};

export const getSuggestedAmounts = memoize((referenceAmount, count = 4) => {
  let suggestedAmounts = [];

  for (let i = 0; i < count; i++) {
    const value = i !== 0 ? suggestedAmounts[i - 1] * suggestedAmountGrowthFactor : referenceAmount;
    suggestedAmounts.push(value);
  }

  return suggestedAmounts;
});

export const generateUUID = () => {
  let basex = 16;
  let d = new Date().getTime();
  if (window.performance && typeof window.performance.now === 'function') {
    d += performance.now(); //use high-precision timer if available
  }
  let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * basex) % basex | 0;
    d = Math.floor(d / basex);
    let sr = 0x3;
    let srx = r & sr;
    let srx1 = 0x8;
    return (c === 'x' ? r : srx | srx1).toString(basex);
  });
  return uuid;
};

export const scaleToWrapperWidth = (targetElement, wrapperElement) => {
  if (wrapperElement) {
    wrapperElement.style.width = '100%';
    wrapperElement.style.position = 'relative';
  }
  const targetElementWidth = targetElement && targetElement.offsetWidth;
  const wrapperElementWidth = wrapperElement && wrapperElement.offsetWidth;
  if (wrapperElementWidth && targetElementWidth) {
    const scaleFactor = wrapperElementWidth / targetElementWidth;
    targetElement.style.transform = `scale(${scaleFactor})`;
    wrapperElement.style.display = 'contents';
  }
};

export const isIosWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(
  navigator.userAgent,
);

export const isIosWebViewWrapper = (baseTheme) => {
  if (isEqual(baseTheme, 'L4')) {
    return false;
  } else {
    return isIosWebView;
  }
};

export const isAndroidWebView = /(android)/i.test(navigator.userAgent);

export const isIOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform,
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const isIPhone = () => [navigator.userAgent.platform, navigator.platform].includes('iPhone');

export const isAndroid = () => {
  const webappOS = detectWebAppOS();
  return webappOS === operatingPlatformsList.mobile.android;
};

export const isDesktopPlatform = () => {
  const deviceDetector = new DeviceDetector();
  const device = deviceDetector.parse(navigator && navigator.userAgent);
  const deviceType = get(device, 'device.type', '');

  return deviceType === 'desktop';
};

export const isMobilePlatform = () => {
  const deviceDetector = new DeviceDetector();
  const device = deviceDetector.parse(navigator && navigator.userAgent);
  const deviceType = get(device, 'device.type', '');

  return deviceType === 'smartphone' || isIPhone();
};

export const getDeviceType = () =>
  (isIOS() && 'ios') || (isAndroid() && 'android') || (isDesktopPlatform() && 'desktop') || '';

/**
 *
 * @param {String} key Key against which the target object is supposed to be matched
 * @param {String} value Value of the key to match provided in the 'key' argument
 * @returns {Object} Target object from onlinePaymentTypesConfig
 */
export const getOnlinePaymentTypeConfig = (key, value) => {
  const targetOnlinePaymentTypesConfigKey = Object.keys(onlinePaymentTypesConfig).find(
    (configKey) => onlinePaymentTypesConfig[configKey][key] === value,
  );

  return onlinePaymentTypesConfig[targetOnlinePaymentTypesConfigKey] || {};
};

export const updateStyles = (styleAttrProp, { bgImage, bgColor, fontSize, fontFamily, color }) => {
  if (bgImage) {
    styleAttrProp.backgroundImage = `url(${bgImage})`;
  }
  if (bgColor) {
    styleAttrProp.backgroundColor = bgColor;
  }
  if (fontSize) {
    styleAttrProp.fontSize = fontSize;
  }
  if (fontSize) {
    styleAttrProp.fontFamily = fontFamily;
  }
  if (color) {
    styleAttrProp.color = color;
  }
};

/**
 * Check if the two primitive-value arrays have the same values irrespective of their order in the two arrays
 *
 * @param {Array} array1
 * @param {Array} array2
 * @returns {Boolean}
 */
export const areSameArraysSansOrder = (array1, array2) =>
  array1.sort().join(',') === array2.sort().join(','); // sort arrays, convert to string and compare

/**
 * Return time salutation from the greeting time salutations Map
 *
 * @param {Date} time
 * @returns {String} salutation
 */
export const getTimeSalutation = (time) => {
  let salutation = greetingTimeSalutationsMap.get(timeOfDayStrings.night); // Return the value against the 'night' key from the greeting time salutations Map by default

  const greetingMatrix = Array.from(greetingTimeSalutationsMap.entries());
  greetingMatrix.forEach((greetingPairArray, index, originArray) => {
    const currentGreetingPair = greetingPairArray; // e.g. ['04:00', 'Hello']
    const currentGreetingHour = parseInt(currentGreetingPair[0].split(':')[0]); // e.g. 4
    const currentGreetingHourSalutation = currentGreetingPair[1]; // e.g. 'Hello'
    const nextGreetingPair =
      index + 1 < originArray.length ? originArray[index + 1] : greetingPairArray; // prevent value out of bounds by falling back to the current value in the iteration
    const nextGreetingHour = parseInt(nextGreetingPair[0].split(':')[0]);

    // If the military-time hour from the arg 'time' is between the range of the greeting military-time hours, then return the corresponding salutation in the greeting time salutations Map
    if (time.getHours() >= currentGreetingHour && time.getHours() < nextGreetingHour) {
      salutation = currentGreetingHourSalutation;
    }
  });

  return salutation;
};

/* intersperse: Return an array with the separator interspersed between
 * each element of the input array.
 *
 * > _([1,2,3]).intersperse(0)
 * [1,0,2,0,3]
 */
export const intersperse = (arr, sep) => {
  if (arr.length === 0) {
    return [];
  }

  return arr.slice(1).reduce((xs, x, i) => xs.concat([sep, x]), [arr[0]]);
};

/**
 * Match a route (URL pathname) from among a list of routes.
 * List of routes may contain URL wildcard pattern e.g. path/*.
 *
 * @param {Array} routeList
 * @param {String} targetRoute
 * @returns {Boolean} Positive match
 */
export const matchRoutePatternWithWildcardSupport = (routeList, targetRoute = '') => {
  if (routeList.includes(targetRoute)) return true;

  return routeList.some((route) => {
    if (!route) return false;

    if (route.includes('*')) {
      const subjectRoutePieces = route.split('/');
      let targetRoutePieces = targetRoute.split('/');

      if (subjectRoutePieces.length === targetRoutePieces.length) {
        return subjectRoutePieces.every(
          (subjectRoutePiece, index) =>
            subjectRoutePiece === '*' || subjectRoutePiece === targetRoutePieces[index],
        );
      } else {
        const index = subjectRoutePieces.findIndex((piece) => piece === '*');
        const includedPieces = subjectRoutePieces.slice(0, index);
        if (includedPieces.length === 0) return false;
        return includedPieces.every((piece) => targetRoutePieces.includes(piece));
      }
    }

    return false;
  });
};

export const handleShareUrl = ({ shareText, shareUrl }) => {
  // check for android web view as  navigator.share doesn't work there
  if (!isIosWebView) {
    window.open(shareUrl, '_blank');
  } else {
    if (navigator.share !== undefined) {
      navigator.share({ text: shareText, url: shareUrl }).then(
        () => console.log('Successful share'),
        (error) => console.log('Error sharing:', error),
      );
    }
  }
};

export const handleMailLink = ({ mailLink }) => {
  const mailUrl = `mailto:${mailLink}`;
  if (window.ReactNativeWebView) {
    const data = {
      shareType: { type: 'mail', url: mailUrl },
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  } else {
    window.open(mailUrl, '_blank');
  }
};

export const handleTierBenefitsLink = (view, isHelpSupportPage) => {
  history.push(
    `${rewardsRoutes.containers.rewardProgramDetails}${
      view || isHelpSupportPage ? `?view=${rewardsRoutes.containers.account}` : ''
    }`,
    {
      tab: 1,
    },
  );
};

export const handleCallClick = ({ countryCode, phoneNumber }) => {
  if (window.ReactNativeWebView) {
    const data = {
      shareType: { type: 'mail', url: `tel:${phoneNumber}` },
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  } else {
    window.open(`tel:${countryCode}${phoneNumber}`, '_self');
  }
};

export const replaceDOMContentWithLinks = (string, countryCode, view, isHelpSupportPage) => {
  const emailPattern = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/gi;
  const phonePattern =
    /(\+?1\s*[-\/\.]?\s*)?\(?[2-9]\d{2}\)?\s*[-\/\.]?\s*[2-9]\d{2}\s*[-\/\.]?\s*\d{4}/g;
  const linkPattern = /<a>(.*?)<\/a>/;
  const newParts = string.split(linkPattern);
  const parts = newParts
    .find((part) => part)
    .trim()
    .split(' ');

  if (newParts[1]) {
    parts.push(newParts[1]);
  }
  return parts.map((part, index) => {
    if (part.match(emailPattern)) {
      return (
        <a key={index} onClick={() => handleMailLink({ mailLink: part })}>
          {part}
        </a>
      );
    } else if (part.match(phonePattern)) {
      return (
        <a key={index} onClick={() => handleCallClick({ countryCode, phoneNumber: part })}>
          {part}
        </a>
      );
    } else if (isEqual(part, newParts[1])) {
      return (
        <div
          className="tier-benefits"
          key={index}
          onClick={() => handleTierBenefitsLink(view, isHelpSupportPage)}
        >
          {part}
        </div>
      );
    }
    return <div>{part}</div>;
  });
};

export const isNativeWebView = !!window.ReactNativeWebView;

export const matchAlphanumericCharacters = (text) => {
  var letters = /^[a-zA-Z0-9]+$/;
  return letters.test(text);
};

export const getComponentVersionProps = (themeData, componentName) => {
  const baseTheme = get(themeData, 'baseTheme', 'L1');
  const baseLayout = layoutConfiguration.find((layout) => isEqual(layout.version, baseTheme));
  const baseComponent = get(baseLayout, 'components', []).find((comp) =>
    isEqual(comp.name, componentName),
  );
  const baseComponentVersion = get(baseComponent, 'version');

  const themeComponent = get(themeData, 'changeComponents', []).find((comp) =>
    isEqual(comp.name, componentName),
  );
  if (isEqual(get(themeComponent, 'status'), 'remove')) {
    return { version: null };
  } else if (isEqual(get(themeComponent, 'status'), 'add')) {
    return {
      version: get(themeComponent, 'version', 'cv1'),
      style: get(themeComponent, 'style', {}),
    };
  }
  const themeComponentVersion = get(themeComponent, 'version', baseComponentVersion);

  const version = themeComponentVersion;
  return { ...themeComponent, version: version, style: get(themeComponent, 'style', {}) };
};

export const isUUID = (value) => {
  const uuidPattern =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return uuidPattern.test(value);
};

export const compareVersions = (version1, version2) => {
  const v1 = version1.split('.').map(Number);
  const v2 = version2.split('.').map(Number);

  const maxLength = Math.max(v1.length, v2.length);

  for (let i = 0; i < maxLength; i++) {
    const num1 = i < v1.length ? v1[i] : 0;
    const num2 = i < v2.length ? v2[i] : 0;

    if (num1 < num2) {
      return -1;
    } else if (num1 > num2) {
      return 1;
    }
  }
  // if versions are same
  return 0;
};

export const handleLinkUrl = ({ linkUrl, context }) => {
  if (isNativeWebView) {
    const data = {
      shareType: { type: 'link-url', url: linkUrl, context },
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  } else {
    window.open(linkUrl, '_blank');
  }
};

export const buildApiUrl = (base_url, params_dict) => {
  if (!base_url.includes('&') && !base_url.endsWith('?')) {
    base_url += '?'; // Adding a question mark if missing
  } else if (!base_url.endsWith('&')) {
    base_url += '&';
  }
  const query_params = [];
  for (const [key, value] of Object.entries(params_dict)) {
    query_params.push(`${key}=${value}`);
  }
  return base_url + query_params.join('&');
};

export const calculateImageHeightWidth = (imageWidth, imageHeight) => {
  if (imageWidth < imageHeight || imageWidth <= imageHeight * 2) {
    return { height: '5.71em', width: 'auto' };
  } else if (imageWidth > imageHeight * 2 && imageWidth <= imageHeight * 3) {
    return { height: '4.57em', width: 'auto' };
  } else if (imageWidth > imageHeight * 3 && imageWidth <= imageHeight * 5) {
    return { height: '4em', width: 'auto' };
  } else if (imageWidth > imageHeight * 5 && imageWidth <= imageHeight * 7) {
    return { height: '3.42em', width: 'auto' };
  } else if (imageWidth === imageHeight) {
    return { height: '8em', width: '8em' };
  } else {
    return { height: 'auto', width: '100%' };
  }
};

export const calculateImageHeightWidthLoginPage = (imageWidth, imageHeight) => {
  if (imageWidth < imageHeight || imageWidth <= imageHeight * 2) {
    return { height: '8.57em', width: 'auto' };
  } else if (imageWidth > imageHeight * 2 && imageWidth <= imageHeight * 3) {
    return { height: '5.71em', width: 'auto' };
  } else if (imageWidth > imageHeight * 3 && imageWidth <= imageHeight * 4) {
    return { height: '5.14em', width: 'auto' };
  } else if (imageWidth > imageHeight * 4 && imageWidth <= imageHeight * 5) {
    return { height: '4em', width: 'auto' };
  } else if (imageWidth > imageHeight * 5 && imageWidth <= imageHeight * 7) {
    return { height: '3.42em', width: 'auto' };
  } else if (imageWidth === imageHeight) {
    return { height: '8.57em', width: '8.57em' };
  } else {
    return { height: 'auto', width: '100%' };
  }
};

export const formatAmount = (amount) => Number(amount).toLocaleString('en-IN') || amount;

export const englishNumbers = [
  'First',
  'Second',
  'Third',
  'Fourth',
  'Fifth',
  'Sixth',
  'Seventh',
  'Eighth',
  'Ninth',
  'Tenth',
];

export const handleDownloadAppClick = ({ appStartupInfo, history }) => {
  const deviceType = getDeviceType();
  /*To DO. Added for backward compatibility to remove dependenct from the reward config */
  const appLinksFromRewardRuleConfig = get(appStartupInfo, 'brand.additionalData.appLinks', {});
  const appLinksObj = get(
    appStartupInfo,
    'brandResource.resource.appLinks',
    appLinksFromRewardRuleConfig,
  );
  const appDownloadLink = appLinksObj[deviceType];
  if (!isEmpty(appDownloadLink)) {
    window.location.href = appDownloadLink;
    return;
  } else {
    history.push(`/${rewardsRoutes.rewards}/${rewardsRoutes.containers.getApp}`);
  }
};

export const getOutletDistance = ({
  latitude1,
  longitude1,
  latitude2,
  longitude2,
  outletDistanceDisplayThresoldInKm,
  nearbyOutletThresoldInMeter,
}) => {
  let outletDistance =
    checkDistanceBetweenCoordinates(
      {
        latitude1,
        longitude1,
        latitude2,
        longitude2,
      },
      false,
    ) || 0;
  if (outletDistance < outletDistanceDisplayThresoldInKm) {
    let outletDistanceInMeter = outletDistance * 1000;
    if (outletDistanceInMeter < nearbyOutletThresoldInMeter)
      return <FormattedMessage id="nearby" defaultMessage={resourcesConfig.nearby} />;
    outletDistanceInMeter = Math.round(outletDistanceInMeter);
    return `${outletDistanceInMeter} m`;
  } else {
    outletDistance = Math.round(outletDistance);
    return `${outletDistance} km`;
  }
};

export const renderOutletDistance = ({
  distance,
  outletDistanceDisplayThresoldInKm,
  nearbyOutletThresoldInMeter,
}) => {
  if (distance < outletDistanceDisplayThresoldInKm) {
    let outletDistanceInMeter = distance * 1000;
    if (outletDistanceInMeter < nearbyOutletThresoldInMeter)
      return <FormattedMessage id="nearby" defaultMessage={resourcesConfig.nearby} />;
    outletDistanceInMeter = Math.round(outletDistanceInMeter);
    return `${outletDistanceInMeter} m`;
  } else {
    distance = Math.round(distance);
    return `${distance} km`;
  }
};

export const fibonacciPolling = (maxTime) => {
  const series = [1, 1];
  let nextValue = 2;

  while (nextValue <= maxTime) {
    series.push(nextValue);
    const len = series.length;
    nextValue = series[len - 1] + series[len - 2];
  }

  return series;
};

export const isInstagramBrowser = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return userAgent.includes('Instagram');
};

export const safeDecode = (uri) => {
  try {
    return decodeURIComponent(uri);
  } catch (error) {
    console.error('safeDecode failed for:', uri, error);
    return uri;
  }
};

export const sanitizeValue = (value) => {
  return value && value.replace(/\s+/g, '');
};
