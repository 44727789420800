const rewardsRoutes = {
  rewards: 'app',
  containers: {
    authentication: 'authentication',
    home: 'home',
    account: 'account',
    locations: 'locations',
    getApp: 'get-app',
    rewardList: 'list',
    dealDetail: 'detail',
    couponDetail: 'coupon',
    rewardProgramDetails: 'program-details',
    referral: 'referral',
    coupons: 'coupons',
    deleteAccountSuccess: 'delete-account-success',
    aboutUs: 'about-us',
    franchiseEnquiry: 'franchise-enquiry',
    ordering: 'ordering',
    deals: 'deals',
    helpSupport: 'help-support',
    scan: 'scan',
    vipPassList: 'pass-list',
    vipPassDetail: 'pass-detail',
    vipMembershipList: 'membership-list',
    payment: 'payment',
    myRewards: 'my-rewards',
    payEarn: 'pay-earn',
    bookTable: 'book-table',
    tableBookings: 'table-bookings',
    delivery: 'delivery-address',
    selectOutlet: 'select-outlet',
    location: 'location',
  },
  authentication: {
    signUp: 'signup',
    signIn: 'signin',
  },
  payEarn: {
    selectBrand: 'select-brand',
    selectOutlet: 'select-outlet',
    enterAmount: 'enter-amount',
    payAmount: 'pay-amount',
    coupons: 'coupons',
    couponDetails: 'couponDetails',
    payment: 'payment',
    confirmation: 'confirmation',
  },
  bookTable: {
    selectBrand: 'select-brand',
    selectOutlet: 'select-outlet',
    selectTable: 'select-table',
    confirmation: 'confirmation',
  },
};

export default rewardsRoutes;
