import { isEmpty } from 'lodash';
import Axios from 'axios';

import client from '../utils/client';

import { geolocationApiUrl, googleAPIKey } from '../constants/configuration';
import { restaurantDataDepthParams, trueCallerRetryCount } from '../constants/globals';
import rewardsRoutes from '../routes/rewards';
import { aiCoreApiUrl } from '../constants/configuration';

// all the apis can be called from here

export const fetchMasterDataApi = ({ appName }) => {
  return client().get(`/lookup/appSettings/${appName}`);
};

export const fetchRestaurantMenuApi = (url) => client().get(url);

export const fetchRestaurantDataApi = ({
  restaurantId,
  depthParam = restaurantDataDepthParams.default,
}) => client().get(`/restaurants/${restaurantId}?depth=${depthParam}`);

export const fetchRestaurantOffersApi = ({ restaurantOffersReqBody }) =>
  client().post('/offers/guestOffers/search', restaurantOffersReqBody);

export const fetchMenuItemCustomizationsApi = ({ restaurantId, menuItemId }) =>
  client().get(`/customizations/groups?restaurantId=${restaurantId}&menuItemId=${menuItemId}`);

export const getGuestByIdApi = async ({ guestId }) => {
  try {
    const { data } = await client().get(
      //url needs to be changed for getting guest by id
      `/guests/${guestId}`,
    );
    return data;
  } catch (error) {
    // get axios errors from error.response
    throw error;
  }
};

export const fetchViewCartItemsApi = (cartItems) => client().post('/orders/cart', cartItems);

export const fetchViewCartItemsApiV2 = (cartItems) => client().post('/orders/cart/v2', cartItems);

export const fetchCartUpsellItemsApi = async (cartItems) =>
  client().post('/restaurantUpsells/cartUpsellItems/search', cartItems);

export const updateCartUpsellItemApi = async (cartItems) =>
  client().patch('/restaurantUpsells/cartUpsellItems/updateIsSeen', cartItems);

export const placeOrderApi = (orderItems) => client().post('/orders', orderItems);

export const placeOrderApiV2 = (orderItems) => client().post('/orders/v2', orderItems);

export const getOrderStatusApi = async (orderId) => {
  try {
    const { data } = await client().get(`/orders/status?orderId=${orderId}`);
    return data;
  } catch (error) {
    // get axios errors from error.response
    throw error;
  }
};

export const fetchOrderedItemsApi = ({
  orderId,
  accessToken,
  includePointTransactions,
  isFeedbackApplicable,
}) => {
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().get(
    `/orders/${orderId}${includePointTransactions ? '?includePointTransactions=true' : ''}${
      isFeedbackApplicable ? '&depth=fdbk' : ''
    }`,
  );
};

export const sendOtpApi = (phoneNumber, countryCode, res) =>
  client().post('/guests/sendAuth', { phone: phoneNumber, country: countryCode, res });

export const attemptLoginApi = async (loginReqBody) =>
  await client().post('/guests/signin', loginReqBody);

export const updateProfileApi = async ({ updateProfileReqBody, guestId }) =>
  await client().put(`/guests/${guestId}`, updateProfileReqBody);

export const getUserProfileDataApi = async ({ guestId }) =>
  await client().get(`/guests/${guestId}`);

export const initiatePaymentApi = ({ initiatePaymentReqBody, defaultGateway }) =>
  client().post(`/payments/initiatePayment/v2`, initiatePaymentReqBody, {
    headers: {
      common: {
        pg: defaultGateway,
      },
    },
  });

export const validatePaymentApi = ({ paymentresponse, defaultGateway }) =>
  client().post('/payments/paymentResponse', paymentresponse, {
    headers: {
      common: {
        pg: defaultGateway,
      },
    },
  });

export const verifyPaymentApi = ({ verifyPaymentReqBody, defaultGateway }) =>
  client().post('/payments/verifyPayment', verifyPaymentReqBody, {
    headers: {
      common: {
        pg: defaultGateway,
      },
    },
  });

export const getCurrentOrderCountApi = ({ guestId, restaurantId, brand, context, status }) => {
  switch (context) {
    case rewardsRoutes.rewards:
      return client().get(
        `/orders/current/counts?guest=${guestId}&includeSubBrands=true${
          brand ? `&brand=${brand}` : ''
        }${status && status.length > 0 ? `&status=${status}` : ''}`,
      );
    default:
      return client().get(
        `/orders/current/counts?guest=${guestId}&includeSubBrands=true${
          restaurantId ? `&restaurant=${restaurantId}` : ''
        }`,
      );
  }
};

export const fetchGuestOrdersApi = ({ url }) => client().get(url);

export const requestAssistanceApi = async ({ requestAssistanceReqBody }) =>
  await client().post('/assistances/addrequest', requestAssistanceReqBody);

export const fetchRestaurantListApi = async ({ fetchRestaurantListReqBody }) =>
  await client().post('/restaurants/search', fetchRestaurantListReqBody);

export const fetchAvailableCitiesApi = () => client().get('/lookup/availableCities');

export const fetchLocalitiesApi = ({ restaurantCityId }) =>
  client().get(`/lookup/localities?cityId=${restaurantCityId}`);

export const findGuestLocationApi = ({ guestId }) =>
  client().get(`/guests/findLocation${!isEmpty(guestId) ? `?guestId=${guestId}` : ''}`);

export const findGeoLocationApi = () => client().post(`${geolocationApiUrl}?key=${googleAPIKey}`);

export const verifyLocalitiesApi = async ({ verifyLocalitiesReqBody }) => {
  try {
    const { data } = await client().post(
      '/restaurants/deliveries/verifyLocalities',
      verifyLocalitiesReqBody,
    );
    return data;
  } catch (error) {
    // get axios errors from error.response
    throw error;
  }
};

export const fetchSelectedTableApi = async (locationId) => {
  try {
    const { data } = await client().get(`/restauranttables/${locationId}`);
    return data;
  } catch (error) {
    // get axios errors from error.response
    throw error;
  }
};

export const getOriginalLinkApi = ({ customLinkKey }) =>
  client().get(`/customLinks/find?key=${customLinkKey}`);

export const deleteGuestVehicleApi = (deleteGuestVehicleReqBody) =>
  client().delete('/guests/vehicles', {
    data: deleteGuestVehicleReqBody,
  });

export const fetchGuestCashCardsApi = ({ url }) => client().get(url);

export const fetchGuestCashCardDetailsApi = ({ guestCashCardId }) =>
  client().get(`/cashCards/guests/${guestCashCardId}`);

export const fetchGuestCashCardTransactionsApi = ({ url }) => client().get(url);

export const fetchRestaurantCashCardApi = ({ restaurantId }) =>
  client().get(`/cashCards?restaurantId=${restaurantId}`);

export const signInByTrueCallerApi = ({ requestId }) =>
  client().get(`/guests/signin/byExternalId?requestId=${requestId}&retry=${trueCallerRetryCount}`);

export const createCashCardRechargeApi = (accessToken, rechargeRequestBody) => {
  return client().post('/cashCards/recharge', rechargeRequestBody);
};

export const validateLocalitiesApi = (validateLocalitiesReqBody) =>
  client().post('/restaurants/deliveries/validateLocalities', validateLocalitiesReqBody);

export const addGuestAddressApi = (addressObjBody) =>
  client().post('/brandGuests/v2', addressObjBody);

export const fetchProgressiveWebAppDataApi = ({ subDomain }) =>
  client().get(`/whiteLabelApps?sd=${subDomain}`);

export const fetchGuestAddressesDataApi = ({ guestId, brandId }) =>
  client().get(
    `/brandGuests/byBrandAndGuest?isRewardOnboarded=true&brandId=${brandId}&guestId=${guestId}&depth=ga`,
  );

// engage specific API's

export const fetchBrandDealsApi = ({ url }) => client().get(url);

export const subscribeDealApi = ({ subscribeDealReqBody }) => {
  return client().post('/deals/addGuests', subscribeDealReqBody);
};

export const fetchSubscribedGuestDealApi = ({ couponCode }) =>
  client().get(`/deals/dealGuests/${couponCode}`);

export const fetchDealByIdApi = ({ dealCode, guestId }) =>
  client().get(
    `/deals/${dealCode}?includeStats=true${
      !isEmpty(guestId) ? `&includeGuestCoupons=true&guestId=${guestId}` : ''
    }`,
  );

export const findGuestByPhoneNumberApi = ({ phoneNumber }) =>
  client().get(`/guests/phoneSearch?phoneNum=${phoneNumber}`);

export const updateDealGuestApi = ({ dealGuestId, updateDealGuestReqBody }) =>
  client().put(`/deals/dealGuests/${dealGuestId}`, updateDealGuestReqBody);

export const getPreviousOrdersApi = ({ guestId, restaurantId, size }) =>
  client().get(`/orders/previous?guestId=${guestId}&resId=${restaurantId}&size=${size}`);

export const fetchGuestPreviousOrderedItemsApi = ({ guestId, restaurantId, size }) =>
  client().get(`/orders/items/previous?guestId=${guestId}&resId=${restaurantId}&size=${size}`);

export const fetchOutletDetailsApi = ({ url }) => client().get(url);

export const createGuestFeedbackApi = (guestFeedbackReqBody) =>
  client().post('/feedback', guestFeedbackReqBody);

export const createOrderFeedbackApi = (orderFeedbackReqBody) =>
  client().post('/feedbacks/create/v2', orderFeedbackReqBody);

export const updateGuestFeedbackApi = ({ guestFeedbackId, guestFeedbackReqBody }) =>
  client().put(`/feedback/${guestFeedbackId}`, guestFeedbackReqBody);

export const getBrandFeedbackSetupApi = ({ brandId }) =>
  client().get(`/brands/feedbacks/setup?brandId=${brandId}`);

export const addFudrGuestApi = ({ signupReqBody }) =>
  client().post(`/guests/signup`, signupReqBody);

export const addBrandGuestApi = ({ addBrandGuestReqBody }) =>
  client().post(`/brandGuests`, addBrandGuestReqBody);

// rewards specific api

export const fetchGuestDealsApi = ({ url }) => client().get(url);
export const fetchGuestCouponsApi = ({ url }) => client().get(url);

export const checkExisitingRewardGuestApi = (params) =>
  client().get(`/brandGuests/byBrandAndGuest?${params}`);

export const updateRewardGuestProfileApi = (updateProfileReqBody) =>
  client().post('/brandGuests/v2', updateProfileReqBody);

export const fetchRewardProgramRulesApi = ({ brandId }) =>
  client().get(`/rewardPrograms?brandId=${brandId}`);

export const fetchBrandResourcesApi = ({ brandId }) => client().get(`/brands/resources/${brandId}`);

export const fetchRewardPointsTransactionsApi = ({ page, size, brandId, brandGuestId, params }) =>
  client().get(
    `/rewardPoints/transactions?page=${page}&size=${size}&brandId=${brandId}&brandGuestId=${brandGuestId}${params}&sort=transactionDate,DESC`,
  );

export const fetchRewardGuestDetailsApi = ({ guestId, depth }) =>
  client().get(`brandGuests/${guestId}${depth ? '?depth=grt' : ''}`);

export const fetchRewardPointsApi = ({ url }) => client().get(url);

export const initiateDeleteAccountApi = ({ accessToken, brandId, deleteAccountReqBody }) => {
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().post(`/brands/initiateDelete/${brandId}`, deleteAccountReqBody);
};

export const initiateDeleteBrandGuestAccountApi = (requestBody) =>
  client().post(`brandGuests/v2`, requestBody);

export const fetchRecentlyVisitedRestaurant = ({ guestId, brandId, size }) =>
  client().get(
    `/restaurants/visited?guestId=${guestId}&brandId=${brandId}&type=RECENT&size=${size}`,
  );

export const fetchAppStartupInfoApi = ({ appStartupReqBody }) =>
  client().post(`/appStartup`, appStartupReqBody);

export const fetchOrderFeedbackSetupApi = ({ brandId }) =>
  client().get(`/brands/feedbacks/setup?brandId=${brandId}`);

export const fetchfeedbackByIdApi = ({ feedbackId }) =>
  client().get(`/brands/feedbacks/${feedbackId}`);

export const fetchBrandCitiesApi = ({ brandId }) => client().get(`/brands/cities/${brandId}`);

export const fetchMenuItemVariationsApi = ({ restaurantId, menuItemId }) =>
  client().get(`/menus/variations?restaurantId=${restaurantId}&menuItemId=${menuItemId}`);

export const fetchVIPPassesApi = ({ url }) => client().get(url);

export const fetchVIPPassByIdApi = ({ passCode, brandGuestId }) =>
  client().get(
    `/passes/${passCode}${
      !isEmpty(brandGuestId) ? `?includeActiveMembership=true&brandGuestId=${brandGuestId}` : ''
    }`,
  );

export const fetchVIPPassCouponsByIdApi = ({ url }) => client().get(url);

export const fetchPassMembershipsApi = ({ url }) => client().get(url);

export const fetchPassMembershipByIdApi = ({ membershipId, depth }) =>
  client().get(`/passMemberships/${membershipId}${depth ? `?depth=${depth}` : ''}`);

export const subscribePassMembershipApi = ({ subscribePassMembershipReqBody }) =>
  client().post(`/passMemberships/subscribe`, subscribePassMembershipReqBody);

export const deleteGuestAddressApi = (guestAddressBody) =>
  client().post('/brandGuests/v2', guestAddressBody);

export const fetchAllSubBrandsApi = ({ brandId }) =>
  client().post('brands/search', { parent: brandId });

export const updatePaymentCartApi = (payload) =>
  client().post('/paymentOrders/paymentCart', payload);

export const updatePaymentCartApiV2 = (payload) =>
  client().post('/paymentOrders/paymentCart/v2', payload);

export const paymentOrderPayApi = (payload) => client().post('/paymentOrders/pay', payload);

export const paymentOrderPayApiV2 = (payload) => client().post('/paymentOrders/pay/v2', payload);

export const paymentOrderDetailsApi = ({ params, orderId }) =>
  client().get(`/paymentOrders/${orderId}${params}`);

export const fetchReservationAvailableSlotsApi = (payload) =>
  client().post(`/reservations/searchAvailableSlots`, payload);

export const createTableReservationApi = ({ accessToken, payload }) => {
  Axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  return client().post(`/reservations/addBooking`, payload);
};

export const fetchTableReservationDetailsApi = ({ id }) =>
  client().get(`reservations/${id}?depth=bo,bg`);

export const fetchGuestTableReservationsApi = ({ url }) => client().get(url);

export const fetchGuestLastOrderApi = ({ url }) => client().get(url);

export const validateCouponAndDealApi = ({ validateCouponAndDealReqBody }) =>
  client().post(`/coupons/validate/v2`, validateCouponAndDealReqBody);

export const fetchCouponAndDealApi = ({ fetchCouponAndDealReqBody }) =>
  client().post(`/coupons/redeemableForOrder/v2`, fetchCouponAndDealReqBody);

export const fetchOrderFeedbackByIdApi = ({ feedbackId }) =>
  client().get(`/feedbacks/${feedbackId}`);

export const fetchDeviceIPApi = () => Axios.get('https://api.ipify.org?format=json');

export const fetchLocationByIPApi = (ip) => Axios.get(`https://ipinfo.io/${ip}/geo`);

export const validateReferralCodeApi = ({ validateReferralCodeReqBody }) =>
  client().post(`referrals/validateCode`, validateReferralCodeReqBody);

export const fetchReferralCodeApi = ({ brandGuestId }) =>
  client().get(`referrals/codes/active?brandGuestId=${brandGuestId}`);

export const generateReferralCodeApi = ({ generateReferralCodeReqBody }) =>
  client().post(`referrals/codes/generate`, generateReferralCodeReqBody);

export const fetchReferralDealsApi = ({ dealIds }) => client().get(`/deals/?dealIds=${dealIds}`);

export const fetchSocialMediaCampaignDetailsApi = ({ url }) =>
  client().get(`/socialMediaCampaigns/${url}`);

export const exchangeCodeForTokenApi = (code) =>
  Axios.post(`${aiCoreApiUrl}/fudrai/ig2/auth/exchange-code`, { code });
